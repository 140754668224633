<template>
  <App
    title=""
    left-arrow
    @click-left="onNavBarClickLeft"
    @click-right="onNavBarClickRight"
  >
    <template #right>
      <van-icon
        name="edit"
        size="18"
        color="#DA3738"
        v-auth="'CustomerDetails-Edit'"
      />
    </template>
    <div v-if="!recordPopupShow">
      <div class="customer-info">
        <div class="header">
          <div class="avatar-wrap">
            <img src="@/assets/images/icon/avatar.png" />
          </div>
          <div class="info-wrap">
            <span class="name">{{ customerInfo.name }}</span>
            <div>
              <Icon name="phone" />
              <span class="value">{{ customerInfo.phone }}</span>
            </div>
            <div class="birthday-wrap">
              <div>
                <Icon name="birthday-cake-o" />
                <span class="value">{{ customerInfo.birthday }}</span>
              </div>
              <div>
                <Icon name="map-marked" />
                <span class="value">{{
                  cityName[customerInfo.city] || customerInfo.city
                }}</span>
              </div>
            </div>
          </div>
          <img src="@/assets/images/icon/wechat@2x.png" alt="" class="wechat-icon" @click="wxDoChat(customerInfo.eosAccountId)">
        </div>
        <div class="intention-wrap">
          <div>
            {{ clueSource[customerInfo.source] || customerInfo.source }}
          </div>
          <div>{{ customerInfo.isToshop ? "Yes" : "No" }}</div>
        </div>
        <!-- <div class="tags">
          <div
            class="tag"
            v-for="($e, $i) in customerInfo.tags"
            :key="'tag-' + $i"
          >
            {{ $e }}
          </div>
        </div> -->
      </div>
      <div class="customer-action">
        <Sidebar v-model="currSidebar" class="slider" @change="changeSidebar">
          <SidebarItem
            v-for="($e, $i) in sidebarArray"
            :title="$e"
            :dot="isDot($i)"
            :key="'sidebar-item-' + $i"
          />
        </Sidebar>
        <div class="action-info">
          <PullRefresh v-show="hasDate" v-model="loading" @refresh="refresh">
            <div class="list-header">
              <div class="title">{{ listTitle }}</div>
              <div class="title-btn">
                <template v-if="currSidebar === 2">
                  <Button
                    round
                    type="info"
                    size="mini"
                    class="btn"
                    @click="toOrder"
                  >
                    <Icon name="plus" color="#ffffff" size="10" />
                    <span class="btn-text">Place Order</span>
                  </Button>
                </template>
              </div>
            </div>
            <List
              v-model="loading"
              class="dataLister"
              :finished="finished"
              finished-text="No more items."
              offset="350"
              @load="getData"
            >
              <CardItem
                v-for="($items, index) in actionInfoList"
                :key="index"
                :id="$items.id"
                :cardInfo="$items"
                :type="currSidebar"
                @showRecord="followupRecord($items.id, $items.type)"
                @createQuote="createQuoteOrder($items)"
              />
            </List>
          </PullRefresh>
          <div v-show="!hasDate"></div>
        </div>
      </div>
    </div>
    <Record
      v-else
      @hideRecord="recordPopupShow = false"
      @editAccount="onNavBarClickRight"
      :type="showType"
      :id="leadsId"
      :customerInfo="customerInfo"
      :data="recordData.data"
      :total="recordData.paginate.total"
    ></Record>
  </App>
</template>

<script>
import {
  Icon,
  Sidebar,
  SidebarItem,
  PullRefresh,
  List,
  Button,
  Toast,
  Dialog,
} from "vant";
import CardItem from "./components/card/index";
import Record from "./components/record/index";
import "vant/lib/icon/style";
import "vant/lib/sidebar/style";
import "vant/lib/sidebar-item/style";
import "vant/lib/popup/style";
import "./index.less";
import { accountinfo } from "@/api/general/index";
import {
  getBrandCity,
  getClueSource2,
  organization,
} from "@/api/chargequery/index";
import {
  ledasoppLeads,
  oppsList,
  testdriveSearch,
  joinActive,
  orderMessage,
  accounttrend,
} from "@/api/clue/index";
import { trendnew } from "@/api/common/index";
import {
  clueStatus,
  oppStatus,
  testdriveStatus,
  surveyStatusAttr,
} from "./attr";
import { orderStatusAttr } from "@/utils/config";
import { orderList } from "@/api/order/index";
import dayjs from "dayjs";
import "@/style/repairIcon.less";
import { wxDoChat } from "@/utils/wechart";
export default {
  name: "customerInfo",
  components: {
    Icon,
    Sidebar,
    SidebarItem,
    PullRefresh,
    List,
    CardItem,
    Button,
    Record,
  },
  data() {
    return {
      wxDoChat,
      ShopList: [],
      attendIsNew: false,
      customerParama: {},
      customerInfo: {},
      sidebarArray: [
        "Leads",
        "Prospects",
        "Orders",
      ],
      currSidebar: 0,
      hasDate: true,
      loading: false,
      drLoading: false,
      showType: "leads",
      finished: false,
      cityName: [],
      clueSource: [],
      actionInfoList: [],
      recordPopupShow: false,
      list: {
        paginate: {
          page: 1,
          size: 3,
          total: 1,
        },
      },
      leadsId: "defalut",
      recordData: {
        paginate: {
          page: 1,
          size: 10,
          total: 1,
        },
        data: [],
      },
    };
  },
  computed: {
    listTitle() {
      let title = "List";
      switch (this.currSidebar) {
        case 0:
          title = "List of Leads";
          break;
        case 1:
          title = "List of Prospects";
          break;
        case 2:
          title = "List of Orders";
          break;
        case 3:
          title = "试驾排程";
          break;
        case 4:
          title = "参与活动";
          break;
        case 5:
          title = "勘测单列表";
          break;
        case 6:
          title = "动向";
          break;
      }
      return title;
    },
    isDot() {
      return function (item) {
        if (item === 6 && this.attendIsNew) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    onNavBarClickLeft() {
      if (this.recordPopupShow) this.recordPopupShow = false;
      else this.$router.go(-1);
    },
    onNavBarClickRight() {
      this.$router.push({
        path: "/customerInfoEdit",
        query: {
          id: this.customerParama.id,
        },
      });
    },
    createSurveyOrder() {
      this.$router.push({
        path: "/createSurveyOrder",
        query: { uid: this.customerInfo.uid },
      });
    },
    changeSidebar() {
      this.list.paginate.page = 1;
      this.finished = false;
      this.actionInfoList = [];
    },
    // 跳转试乘试驾
    toTestDrive() {
      this.$router.push({
        path: "/testDriveCreate",
        query: {
          phone: this.customerInfo.phone,
        },
      });
    },
    // 跳转DR订单
    toDROrder() {
      // 校验当前客户是否已有有效DR订单
      const params = {
        channel: this.$storage.get("channel"),
        uidList: [{ uid: this.customerInfo.uid }],
        orderClass: "2",
        page: {
          index: 1,
          size: 10,
        },
      };
      this.drLoading = true;
      orderList(params)
        .then((res) => {
          const status = ["01", "05", "06"];
          const data = res.data.pgdataList.filter(
            (item) => !status.includes(item.status)
          );
          return data.length === 0;
        })
        .then((valid) => {
          if (valid) {
            this.$router.push({
              path: "/createOrder",
              query: {
                type: 1,
                name: this.customerInfo.name,
                phone: this.customerInfo.phone,
                superId: this.customerInfo.superId,
                uid: this.customerInfo.uid,
              },
            });
            return;
          }
          Dialog.alert({
            title: "DR提示",
            message: "您已为此客户创建过DR订单，不可以继续创建新DR订单",
          }).then(() => {
            Dialog.close();
          });
        })
        .finally(() => {
          this.drLoading = false;
        });
    },
    // 获取上牌城市
    async getBrandCity() {
      const res = await getBrandCity();
      if (res.code === "000000") {
        const k = {};
        res.data.map((e) => {
          k[e.intentionCity] = e.cityName;
        });
        this.cityName = k;
      } else {
        Toast(res.msg);
      }
      return new Promise((resole) => resole());
    },
    // 获取线索来源
    async getClueSource() {
      const res = await getClueSource2();
      if (res.success) {
        const k = {};
        res.data.map((e) => {
          k[e.threelevelCode] = e.threelevelName;
        });
        this.clueSource = k;
      } else {
        Toast(res.msg);
      }
      return new Promise((resole) => resole());
    },
    // 跳转订单
    toOrder() {
      this.$router.push({
        path: "/createOrder",
        query: {
          name: this.customerInfo.name,
          phone: this.customerInfo.phone,
          superId: this.customerInfo.superId,
          uid: this.customerInfo.uid,
        },
      });
    },
    // 获取客户详情
    async getCustomerInfo() {
      const res = await accountinfo({
        id: this.customerParama.id,
      });
      if (res.success) {
        this.customerInfo = res.data;
        this.customerInfo.tags = [];
        if (res.data.profile) {
          Object.values(JSON.parse(res.data.profile)).map((e) => {
            if (!e.trees) return false;
            e.trees.forEach((te) => {
              this.customerInfo.tags.push(te.name);
            });
          });
        }
      } else {
        Toast("请求数据失败");
      }
      // 查询新动向
      this.getTrendnew();
    },
    async getTrendnew() {
      const bres = await trendnew({ accountId: this.customerParama.id });
      if (bres.success) {
        this.attendIsNew = bres.data;
      }
    },
    // 获取线索
    async getLedasoppLeads() {
      const res = await ledasoppLeads(
        {
          page: this.list.paginate.page,
          size: this.list.paginate.size,
        },
        {
          accoundId: this.customerParama.id,
        }
      );
      await this.getClueSource();
      Toast.clear();
      if (res.success) {
        if (res.data.records.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        res.data.records.map((e) => {
          this.actionInfoList.push({
            models: e.intentionCarSeries,
            counselor: e.ownerUserName,
            score: e.score,
            source: this.clueSource[e.threeLevelChannel],
            createTime: dayjs(e.createtime).format("YYYY-MM-DD HH:mm:ss"),
            followUpTime:
              e.nextFollowUpTime !== null
                ? dayjs(e.nextFollowUpTime).format("YYYY-MM-DD")
                : null,
            status: e.status,
            statusTxt: clueStatus[e.status],
            id: e.eosLeadsId, // id不确定
            type: "leads",
          });
        });
        if (this.actionInfoList.length === res.data.total) this.finished = true;
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.total;
      } else {
        const reload = this.$storage.get("customerInfo_ledas_reload");
        if (res.stateCode === 0 && !reload) {
          this.$storage.set("customerInfo_ledas_reload", true);
          location.reload();
          return;
        }
        this.loading = false;
        this.finished = true;
        Toast("请求数据失败");
      }
    },
    // 线索/商机跟进记录
    async followupRecord(id, type) {
      this.recordPopupShow = true;
      this.showType = type;
      this.leadsId = id;
    },
    // 商机列表
    async getOppsList() {
      const res = await oppsList(
        {
          page: this.list.paginate.page,
          size: this.list.paginate.size,
        },
        {
          accoundId: this.customerParama.id,
        }
      );
      Toast.clear();
      if (res.success) {
        if (res.data.records.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        // eslint-disable-next-line
        let opp_conversion_source = {};
        this.$storage.get("baseData").opp_conversion_source.forEach((e) => {
          opp_conversion_source[e.value] = e.key;
        });
        res.data.records.map((e) => {
          // 计算poc数量
          let poc1 = 0;
          let poc2 = 0;
          let poc3 = 0;
          e.pocOwnerType ? (poc1 = 1) : (poc1 = 0);
          e.pocLoanFlag ? (poc2 = 1) : (poc2 = 0);
          e.pocLicenseFlag ? (poc3 = 1) : (poc3 = 0);
          // 归属门店映射
          this.ShopList.forEach((el) => {
            if (el.code == e.qbsStoreCode) e.storeNamed = el.name;
          });
          this.actionInfoList.push({
            models: e.stiIntentionCarSeries,
            counselor: e.ownerUserName,
            stores: e.storeNamed,
            source: opp_conversion_source[e.conversionSource],
            createTime: e.createtime
              ? dayjs(e.createtime).format("YYYY-MM-DD HH:mm:ss")
              : "",
            followUpTime: e.nextFollowUpTime
              ? dayjs(e.nextFollowUpTime).format("YYYY-MM-DD")
              : "",
            closeDate:
              e.closedate == null
                ? null
                : dayjs(e.closedate).format("YYYY-MM-DD"),
            isFc: e.closedate != null ? true : false,
            status: e.status,
            statusTxt: oppStatus[e.status],
            poc: `${2 + poc1 + poc2 + poc3}/5`,
            accountId: this.customerParama.id,
            id: e.eosOppId,
            type: "opp",
          });
        });
        if (this.actionInfoList.length === res.data.total) this.finished = true;
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.total;
      } else {
        Toast("请求数据失败");
      }
    },
    // 订单列表
    async getOrderList() {
      const res = await orderList({
        channel: this.$storage.get("channel"),
        page: {
          index: this.list.paginate.page,
          size: this.list.paginate.size,
        },
        uidList: [
          {
            uid: this.customerInfo.uid,
          },
        ],
      });
      Toast.clear();
      if (res.success) {
        if (res.data.pgdataList.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        res.data.pgdataList.map((e) => {
          this.actionInfoList.push({
            orderNo: e.orderNum,
            name: e.name,
            mobile: e.mobile,
            models: e.carType,
            cityName: e.cityName,
            createdTime: dayjs(e.createdTime * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            status: e.status,
            statusTxt: orderStatusAttr[e.status],
            makeEmpName: e.makeEmpName,
            id: e.orderNum,
          });
        });
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.page.total;
      } else {
        Toast("请求数据失败");
      }
    },
    // 获取组织信息
    async organization() {
      const { data, msg, success } = await organization({ orgcode: "" });
      if (success) {
        this.ShopList = [];
        this.convertTree(data);
      } else {
        this.$Message.error(msg);
      }
    },
    convertTree(tree) {
      tree.forEach((item) => {
        this.ShopList.push({ code: item.data.code, name: item.data.name });
        this.convertTree(item.children);
      });
    },
    // 试驾列表
    async getTestDrive() {
      const res = await testdriveSearch({
        accountPhone: this.customerInfo.phone,
        page: this.list.paginate.page,
        size: this.list.paginate.size,
      });
      Toast.clear();
      if (res.success) {
        if (res.data.records.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        res.data.records.map((e) => {
          this.actionInfoList.push({
            title: e.appointmentDate,
            stores: e.storeName,
            preTime: e.appointmentTime,
            counselor: e.ownerName, // 不确定
            coPilot: e.coPilotName, // 不确定
            createTime: dayjs(e.createtime).format("YYYY/MM/DD HH:mm:ss"), // 不确定
            statusTxt: testdriveStatus[e.testDriveStatus],
            status: e.status,
            id: e.testDriveId,
          });
        });
        if (this.actionInfoList.length === res.data.total) this.finished = true;
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.total;
      } else {
        Toast("请求数据失败");
      }
    },
    // 活动列表
    async getJoinActive() {
      const res = await joinActive({
        accoundId: this.customerParama.id,
        page: this.list.paginate.page,
        size: this.list.paginate.size,
      });
      Toast.clear();
      if (res.success) {
        if (res.data.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        res.data.map((e) => {
          this.actionInfoList.push({
            _startTime: e.startTime
              ? dayjs(e.startTime).format("YYYY/MM/DD")
              : "",
            _endTime: e.endTime ? dayjs(e.endTime).format("YYYY/MM/DD") : "",
            name: e.name,
            singInTime: e.createtime
              ? dayjs(e.createtime).format("YYYY/MM/DD HH:mm:ss")
              : "",
            site: e.place,
            statusText: e.status
              ? e.status === "1"
                ? "已签到"
                : "未签到"
              : "",
          });
        });
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = this.actionInfoList.length;
      } else {
        Toast("请求数据失败");
      }
    },
    // 获取勘测单
    async getOrderMessage() {
      const res = await orderMessage({
        uid: this.customerInfo.uid,
        pageNum: this.list.paginate.page,
        pageSize: this.list.paginate.size,
      });
      Toast.clear();
      if (res.success) {
        if (res.data.list.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        const timeFomat = (t) => {
          let apm = "上午";
          if (dayjs(t).format("HH") > 12) {
            apm = "下午";
          } else {
            if (dayjs(t).format("HH") == 12) {
              if (dayjs(t).format("MM") > 1 || dayjs(t).format("SS") > 1) {
                apm = "下午";
              }
            } else {
              apm = "上午";
            }
          }
          return `${dayjs(t).format("YYYY/MM/DD")} ${apm}`;
        };
        res.data.list.map((e) => {
          this.actionInfoList.push({
            orderNo: e.installOrderNo,
            surveyTime: timeFomat(e.surveyVisitTime),
            name: e.serviceStar.name,
            phone: e.serviceStar.tel,
            createTime: e.createTime,
            statusTxt: surveyStatusAttr[e.status],
            status: e.status,
            id: e.id.toString(),
          });
        });
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.total;
      } else {
        Toast("请求数据失败");
      }
    },
    // 获取客户倾向
    async getAccounttrend() {
      const res = await accounttrend({
        accountId: this.customerParama.id,
        page: this.list.paginate.page,
        size: this.list.paginate.size,
      });
      Toast.clear();
      if (res.success) {
        if (res.data.records.length < 1) {
          this.loading = false;
          this.finished = true;
          return false;
        }
        // 无数据
        res.data.records.map((e) => {
          this.actionInfoList.push({
            name: e.retentionName,
            time: e.activitytime,
            channel: e.sourceName,
            info: e.details, // 不确定
            id: e.eosTrendsId.toString(),
          });
          return;
        });
        if (this.actionInfoList.length === res.data.total) this.finished = true;
        this.loading = false;
        this.list.paginate.page = this.list.paginate.page + 1;
        this.list.paginate.total = res.data.total;
      } else {
        Toast("请求数据失败");
      }
      this.getTrendnew();
    },
    async getData() {
      this.customerParama = this.$route.query;
      // this.finished = false
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
        loadingType: "spinner",
        overlay: true,
        duration: 0,
      });
      if (this.currSidebar === 0) {
        this.getLedasoppLeads();
      }
      if (this.currSidebar === 1) {
        this.getOppsList();
      }
      if (this.currSidebar === 2) {
        this.getOrderList();
      }
      if (this.currSidebar === 3) {
        this.getTestDrive();
      }
      if (this.currSidebar === 4) {
        this.getJoinActive();
      }
      if (this.currSidebar === 5) {
        this.getOrderMessage();
      }
      if (this.currSidebar === 6) {
        this.getAccounttrend();
      }
    },
    async init() {
      this.currSidebar = 0;
      this.customerParama = this.$route.query;
      const cu_path_newCreateForm = this.$storage.get("cu_path_newCreateForm");
      if (cu_path_newCreateForm) {
        this.$storage.remove("cu_path_newCreateForm");
        location.reload();
      }
      this.organization();
      await this.getBrandCity();
      this.getCustomerInfo();
    },
    refresh() {
      this.list.paginate.page = 1;
      this.finished = false;
      this.actionInfoList = [];
      this.getData();
    },
    // 报价
    createQuoteOrder(item) {
      this.$router.push({
        path: "/createQuoteOrder",
        query: {
          leadsId: item.id,
          name: this.customerInfo.name,
          phone: this.customerInfo.phone,
        },
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$storage.remove("customerInfo_ledas_reload");
  },
};
</script>
