<template>
  <Card class="customerInfoCard">
    <template v-if="type === 0">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">高合{{ cardInfo.models }}</h5>
          <span class="status">{{ cardInfo.statusTxt }}</span>
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="Sales Advisor" :value="cardInfo.counselor" />
          <CardCell label="Source" :value="cardInfo.source" />
          <CardCell label="Created Time" :value="cardInfo.createTime" />
          <CardCell
            label="Next Follow-up Time"
            v-if="
              cardInfo.status !== 'CONVERTED' && cardInfo.followUpTime !== null
            "
            :value="cardInfo.followUpTime"
          />
          <p class="score">
            {{ cardInfo.score }}
          </p>
        </Cols>
      </Row>
      <div class="detail-footer">
        <Button
          plain
          round
          type="info"
          size="mini"
          class="btn"
          @click="createQuote"
          >Quote</Button
        >
        <Button
          plain
          round
          type="info"
          size="mini"
          class="btn"
          @click="followUpRecord(cardInfo.id, 'leads')"
          >Follow-up History</Button
        >
        <Button
          v-if="cardInfo.status !== 'CONVERTED'"
          round
          type="info"
          size="mini"
          class="btn"
          @click="followUp(cardInfo.id, 'leads')"
          >Follow up</Button
        >
      </div>
    </template>
    <template v-if="type === 1">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">高合{{ cardInfo.models }}</h5>
          <span class="status">{{ cardInfo.statusTxt }}</span>
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="Sales Advisor" :value="cardInfo.counselor" />
          <CardCell label="Outlet" :value="cardInfo.stores" />
          <CardCell label="Source" :value="cardInfo.source" />
          <CardCell label="Created Time" :value="cardInfo.createTime" />
          <CardCell
            label="Next Follow-up Time"
            v-if="cardInfo.followUpTime"
            :value="cardInfo.followUpTime"
          />
          <CardCell
            v-if="cardInfo.closeDate != null"
            label="Close Date"
            :value="cardInfo.closeDate"
          />
          <p
            class="score"
            v-if="cardInfo.isFc && cardInfo.closeDate != undefined"
          >
            FC
          </p>
        </Cols>
      </Row>
      <div class="detail-footer">
        <Button
          plain
          round
          type="info"
          size="mini"
          class="btn"
          @click="followUpRecord(cardInfo.id, 'opp')"
          >Follow-up History</Button
        >
        <Button
          v-if="cardInfo.status !== 'CONVERTED'"
          round
          type="info"
          size="mini"
          class="btn"
          @click="followUp(cardInfo.id, 'opp')"
          >Follow up</Button
        >
      </div>
    </template>
    <template v-if="type === 2">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">
            <strong @click="jump2order(cardInfo.orderNo)" class="status"
              >No.{{ cardInfo.orderNo }}</strong
            >
            <Tag
              v-clipboard:copy="cardInfo.orderNo"
              v-clipboard:success="onCopy"
              plain
              round
              color="#DA3738"
              class="link-text"
              >Copy</Tag
            >
          </h5>
          <span class="status">{{ cardInfo.statusTxt }}</span>
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="Buyer Name" :value="cardInfo.name" />
          <CardCell label="Buyer Phone Number" :value="cardInfo.mobile" />
          <CardCell label="Model" :value="cardInfo.models" />
          <CardCell label="City" :value="cardInfo.cityName" />
          <CardCell label="Created Time" :value="cardInfo.createdTime" />
          <CardCell label="Sales Advisor" :value="cardInfo.makeEmpName" />
        </Cols>
      </Row>
    </template>
    <template v-if="type === 3">
      <template slot="title">
        <div class="card-info" @click="testdriveDetail(cardInfo.id)">
          <h5 class="models">{{ cardInfo.title }}</h5>
          <span class="status">{{ cardInfo.statusTxt }}</span>
        </div>
      </template>
      <Row @click="testdriveDetail(cardInfo.id)">
        <Cols span="24">
          <CardCell label="预约门店" :value="cardInfo.stores" />
          <CardCell label="预约时间" :value="cardInfo.preTime" />
          <CardCell label="Sales Advisor" :value="cardInfo.counselor" />
          <CardCell label="Co-Pilot" :value="cardInfo.coPilot" />
          <CardCell label="Created Time" :value="cardInfo.createTime" />
        </Cols>
      </Row>
    </template>
    <template v-if="type === 4">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">{{ cardInfo.name }}</h5>
          <span class="status" v-if="cardInfo.statusText">{{
            cardInfo.statusTxt
          }}</span>
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="活动日期">
            <template slot="value"
              >{{ cardInfo._startTime }} - {{ cardInfo._endTime }}</template
            >
          </CardCell>
          <CardCell label="活动地点" :value="cardInfo.site" />
          <CardCell label="签到时间" :value="cardInfo.singInTime" />
        </Cols>
      </Row>
    </template>
    <template v-if="type === 5">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">
            No.{{ cardInfo.orderNo }}
            <Tag
              v-clipboard:copy="cardInfo.orderNo"
              v-clipboard:success="onCopy"
              plain
              round
              color="#DA3738"
              class="link-text"
              >复制</Tag
            >
          </h5>
          <span
            :class="[
              'status',
              cardInfo.status === '勘测异常' ? 'abnormal' : '',
            ]"
            >{{ cardInfo.statusTxt }}</span
          >
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="预约勘测时间" :value="cardInfo.surveyTime" />
          <CardCell label="服务明星" :value="cardInfo.name" />
          <CardCell label="服务明星手机号" :value="cardInfo.phone" />
          <CardCell label="Created Time" :value="cardInfo.createTime" />
          <template v-if="cardInfo.status === '勘测异常'">
            <CardCell label="异常原因" :value="cardInfo.abnormalReason" />
            <CardCell label="异常说明" :value="cardInfo.abnormalRemark" />
          </template>
        </Cols>
      </Row>
      <!-- <template slot="footer"> -->
      <div class="detail-footer">
        <span></span>
        <Button
          round
          type="info"
          size="mini"
          class="btn"
          @click="surveyInfo(cardInfo.orderNo)"
          >详情</Button
        >
      </div>

      <!-- </template> -->
    </template>
    <template v-if="type === 6">
      <template slot="title">
        <div class="card-info">
          <h5 class="models">{{ cardInfo.name }}</h5>
        </div>
      </template>
      <Row>
        <Cols span="24">
          <CardCell label="时间" :value="cardInfo.time" />
          <CardCell label="渠道" :value="cardInfo.channel" />
          <CardCell label="详情" :value="cardInfo.info" />
        </Cols>
      </Row>
    </template>
  </Card>
</template>
<script>
import { Button, Row, Col, Toast, Tag } from "vant";
import Card from "_c/card";
import CardCell from "_c/card/cardCell";
import "vant/lib/row/style";
import "vant/lib/col/style";
import "@/style/vant-patch.less";
import "./index.less";
export default {
  name: "CardItem",
  props: {
    cardInfo: {
      type: Object,
      require: true,
    },
    type: {
      type: Number,
      require: true,
    },
    id: {
      type: String,
      require: true,
    },
  },
  data() {
    return {};
  },
  components: {
    Card,
    Button,
    CardCell,
    Row,
    Cols: Col,
    Tag,
  },
  methods: {
    again(status) {
      status = status.toLowerCase();
      if (
        status === "deposit_reserved" ||
        status === "order" ||
        status === "delivered" ||
        status === "close_cancelled"
      ) {
        return false;
      } else {
        return true;
      }
    },
    followUpInfo(type) {
      this.$router.push({ path: "/followUpInfo", query: { type } });
    },
    followUp(id, type) {
      // 跳转线索跟进页面，传入线索id
      if (type === "leads") {
        this.$router.push({
          path: "/cluesFollowUpFrom",
          query: {
            eosLeadsId: id,
          },
        });
      }
      // 跳转商机跟进页面，传入商机id
      if (type === "opp") {
        this.$router.push({
          path: "/businessOpportunitiesFollowUpFrom",
          query: {
            eosOppId: id,
          },
        });
      }
    },
    followUpRecord(id, type) {
      this.$emit("showRecord", {
        id: id,
        type: type,
      });
    },
    // 跳转到试驾详情
    testdriveDetail(id) {
      this.$router.push({ path: "/testDriveDetail", query: { id } });
    },
    // 跳转到重新分配页面
    redistribution(type) {
      this.$router.push({
        path: "/redistribution",
        query: { id: this.id, type: type },
      });
    },
    surveyInfo(installOrderNo) {
      this.$router.push({
        path: "/surveyInfo",
        query: { installOrderNo: installOrderNo },
      });
    },
    jump2order(orderNum) {
      this.$router.push({
        path: "/orderDetail",
        query: { orderNum: orderNum },
      });
    },
    toPoc(accountId) {
      this.$router.push({
        path: "/poc",
        query: { accountId: accountId, oppId: this.id },
      });
    },
    onCopy() {
      Toast("复制成功!");
    },
    // 报价
    createQuote() {
      this.$emit("createQuote");
    },
  },
  mounted() {},
};
</script>